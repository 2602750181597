import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
import { TOC, TOCList, TOCLink } from "../components/TOC";
import { PropTable } from "../components/PropTable";
import { listboxDefs } from "../componentDefs";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Listbox" description="Accessible listbox dropdown component for React" mdxType="SEO" />
    <h1 {...{
      "id": "listbox"
    }}>{`Listbox`}</h1>
    <TOC mdxType="TOC">
  <TOCList mdxType="TOCList">
    <TOCLink href="#listbox-1" mdxType="TOCLink">Listbox</TOCLink>
    <TOCLink href="#listboxinput" mdxType="TOCLink">ListboxInput</TOCLink>
    <TOCLink href="#listboxbutton" mdxType="TOCLink">ListboxButton</TOCLink>
    <TOCLink href="#listboxarrow" mdxType="TOCLink">ListboxArrow</TOCLink>
    <TOCLink href="#listboxpopover" mdxType="TOCLink">ListboxPopover</TOCLink>
    <TOCLink href="#listboxlist" mdxType="TOCLink">ListboxList</TOCLink>
    <TOCLink href="#listboxoption" mdxType="TOCLink">ListboxOption</TOCLink>
    <TOCLink href="#listboxgroup" mdxType="TOCLink">ListboxGroup</TOCLink>
    <TOCLink href="#listboxgrouplabel" mdxType="TOCLink">ListboxGroupLabel</TOCLink>
    <TOCLink href="#uselistboxcontext" mdxType="TOCLink">useListboxContext</TOCLink>
  </TOCList>
    </TOC>
    <ul>
      <li parentName="ul">{`Source: `}<a parentName="li" {...{
          "href": "https://github.com/reach/reach-ui/tree/main/packages/listbox"
        }}>{`https://github.com/reach/reach-ui/tree/main/packages/listbox`}</a></li>
      <li parentName="ul">{`WAI-ARIA: `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.2/#Listbox"
        }}>{`https://www.w3.org/TR/wai-aria-practices-1.2/#Listbox`}</a></li>
    </ul>
    <p>{`A listbox presents a list of selectable options in a popover, which is toggled on or off by a button. Visually and behaviorally it is similar to `}<a parentName="p" {...{
        "href": "https://reach.tech/menu-button"
      }}><inlineCode parentName="a">{`@reach/menu-button`}</inlineCode></a>{` except that `}<inlineCode parentName="p">{`ListboxOption`}</inlineCode>{` components hold a value. In this sense they are more directly comparable to HTML select elements.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
(() => {
  function BasicExample() {
    let labelId = \`taco-label--\${useId()}\`;
    return (
      <div>
        <VisuallyHidden id={labelId}>Choose a taco</VisuallyHidden>
        <Listbox aria-labelledby={labelId}>
          <ListboxOption value="default">Choose a taco</ListboxOption>
          <ListboxOption value="asada">Carne Asada</ListboxOption>
          <ListboxOption value="pollo" label="Pollo" disabled>
            Pollo <Tag>Sold Out!</Tag>
          </ListboxOption>
          <div style={{ background: "#ccc" }}>
            <ListboxOption value="pastor" label="Pastor">
              Pastor <Tag>Fan favorite!</Tag>
            </ListboxOption>
          </div>
          <ListboxOption value="lengua">Lengua</ListboxOption>
        </Listbox>
      </div>
    );
  }

  function Tag(props) {
    return (
      <span
        style={{
          display: "inline-block",
          lineHeight: 1,
          fontSize: 11,
          textTransform: "uppercase",
          fontWeight: "bolder",
          marginLeft: 6,
          padding: 4,
          background: "crimson",
          borderRadius: 2,
          color: "#fff",
        }}
        {...props}
      />
    );
  }

  return <BasicExample />;
})();
`}</code></pre>
    <p>{`You can use `}<inlineCode parentName="p">{`Listbox`}</inlineCode>{` as a simple standalone component, or compose its parts with `}<inlineCode parentName="p">{`ListboxInput`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
(() => {
  function ComposedExample() {
    let labelId = \`taco-label--\${useId()}\`;
    let [value, setValue] = React.useState("pollo");
    return (
      <div>
        <VisuallyHidden id={labelId}>Choose a taco</VisuallyHidden>
        <ListboxInput
          aria-labelledby={labelId}
          value={value}
          onChange={(value) => setValue(value)}
        >
          <ListboxButton arrow="▼" />
          <ListboxPopover>
            <ListboxList>
              <ListboxOption value="default">Choose a taco</ListboxOption>
              <ListboxOption value="asada">Carne Asada</ListboxOption>
              <ListboxOption value="pollo">Pollo</ListboxOption>
              <ListboxOption value="pastor">Pastor</ListboxOption>
              <ListboxOption value="lengua">Lengua</ListboxOption>
            </ListboxList>
            <div
              style={{
                padding: "10px 10px 0",
                marginTop: 10,
                borderTop: "1px solid gray",
              }}
            >
              <p>I really like tacos. I hope you enjoy them as well!</p>
            </div>
          </ListboxPopover>
        </ListboxInput>
      </div>
    );
  }

  return <ComposedExample />;
})();
`}</code></pre>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`From the command line in your project directory, run `}<inlineCode parentName="p">{`npm install @reach/listbox`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn add @reach/listbox`}</inlineCode>{`. Then import the components and styles that you need:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @reach/listbox
# or
yarn add @reach/listbox
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import {
  Listbox,
  ListboxInput,
  ListboxButton,
  ListboxPopover,
  ListboxList,
  ListboxOption,
} from "@reach/listbox";
import "@reach/listbox/styles.css";
`}</code></pre>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Basic listbox
function Example() {
  return (
    <Listbox defaultValue="popeyes">
      <ListboxOption value="bojangles">Bojangles'</ListboxOption>
      <ListboxOption value="churchs">Church's</ListboxOption>
      <ListboxOption value="kfc">KFC</ListboxOption>
      <ListboxOption value="popeyes">Popeyes</ListboxOption>
    </Listbox>
  );
}

// Composed listbox components
function ExampleComposed() {
  return (
    <ListboxInput defaultValue="popeyes">
      <ListboxButton />
      <ListboxPopover>
        <ListboxList>
          <ListboxOption value="bojangles">Bojangles'</ListboxOption>
          <ListboxOption value="churchs">Church's</ListboxOption>
          <ListboxOption value="kfc">KFC</ListboxOption>
          <ListboxOption value="popeyes">Popeyes</ListboxOption>
        </ListboxList>
      </ListboxPopover>
    </ListboxInput>
  );
}
`}</code></pre>
    <h2 {...{
      "id": "component-api"
    }}>{`Component API`}</h2>
    <h3 {...{
      "id": "listbox-1"
    }}>{`Listbox`}</h3>
    <p>{`The wrapper component for the high-level listbox API.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<div>
  <span id="my-label">Choose a topic</span>
  <Listbox aria-labelledby="my-label" defaultValue="comedy">
    <ListboxOption value="drama">Drama</ListboxOption>
    <ListboxOption value="comedy">Comedy</ListboxOption>
    <ListboxOption value="suspense">Suspense</ListboxOption>
    <ListboxOption value="horror">Horror</ListboxOption>
  </Listbox>
</div>
`}</code></pre>
    <h4 {...{
      "id": "controlled-listbox"
    }}>{`Controlled Listbox`}</h4>
    <p>{`If you want to control the state of the listbox's value, you can do so by passing `}<a parentName="p" {...{
        "href": "#listboxinput-value"
      }}><inlineCode parentName="a">{`value`}</inlineCode></a>{` and `}<a parentName="p" {...{
        "href": "#listboxinput-onchange"
      }}><inlineCode parentName="a">{`onChange`}</inlineCode></a>{` props. The value corresponds with the value of the selected `}<inlineCode parentName="p">{`ListboxOption`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`const [value, setValue] = React.useState("comedy");
return (
  <div>
    <span id="my-label">Choose a movie genre</span>
    <Listbox aria-labelledby="my-label" value={value} onChange={setValue}>
      <ListboxOption value="drama">Drama</ListboxOption>
      <ListboxOption value="comedy">Comedy</ListboxOption>
      <ListboxOption value="suspense">Suspense</ListboxOption>
      <ListboxOption value="horror">Horror</ListboxOption>
    </Listbox>
  </div>
);
`}</code></pre>
    <h4 {...{
      "id": "listbox-props"
    }}>{`Listbox Props`}</h4>
    <PropTable componentName="Listbox" propDefs={listboxDefs.Listbox.propDefs} mdxType="PropTable" />
    <h5 {...{
      "id": "listbox-arrow"
    }}>{`Listbox arrow`}</h5>
    <p><inlineCode parentName="p">{`arrow?: boolean | React.ReactNode`}</inlineCode></p>
    <p>{`Renders a text string or React node to represent an arrow inside the `}<inlineCode parentName="p">{`ListboxButton`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Listbox arrow /> // renders a default arrow character
<Listbox arrow={<span>▼</span>} /> // renders a component as an arrow character
`}</code></pre>
    <p>{`If you want to customize the appearance and placement of the arrow inside the button further, you can drop down to the composed API and `}<a parentName="p" {...{
        "href": "#listboxbutton-children"
      }}>{`render the button's children`}</a>{`.`}</p>
    <h5 {...{
      "id": "listbox-button"
    }}>{`Listbox button`}</h5>
    <p><inlineCode parentName="p">{`button?: React.ReactNode | ((props: { value: string | null; label: string | null; }) => React.ReactNode)`}</inlineCode></p>
    <p>{`A render function or React node to to render the Listbox button's inner content. `}<a parentName="p" {...{
        "href": "#listboxbutton-children"
      }}>{`See the API for the ListboxButton children prop for details.`}</a></p>
    <h5 {...{
      "id": "listbox-children"
    }}>{`Listbox children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p><inlineCode parentName="p">{`Listbox`}</inlineCode>{` should accept `}<inlineCode parentName="p">{`ListboxOption`}</inlineCode>{` components as children.`}</p>
    <p>{`You can also pass arbitrary elements as needed, but be careful when passing elements that have semantic value into listbox directly (such as `}<inlineCode parentName="p">{`button`}</inlineCode>{`). If you need such elements in the popover, chances are they either need to be nested inside an option or elsewhere outside of the list of options. In the latter case, use `}<inlineCode parentName="p">{`ListboxInput`}</inlineCode>{` and the composed API.`}</p>
    <h5 {...{
      "id": "listbox-defaultvalue"
    }}>{`Listbox defaultValue`}</h5>
    <p><inlineCode parentName="p">{`defaultValue?: string`}</inlineCode></p>
    <p>{`The default value of an uncontrolled listbox.`}</p>
    <h5 {...{
      "id": "listbox-disabled"
    }}>{`Listbox disabled`}</h5>
    <p><inlineCode parentName="p">{`disabled?: boolean`}</inlineCode></p>
    <p>{`Whether or not the listbox is disabled.`}</p>
    <h5 {...{
      "id": "listbox-form"
    }}>{`Listbox form`}</h5>
    <p><inlineCode parentName="p">{`form?: string`}</inlineCode></p>
    <p>{`The ID of a form associated with the listbox and its hidden input field. If Listbox is passed directly inside of its associated form this prop can be omitted so long as the `}<inlineCode parentName="p">{`name`}</inlineCode>{` prop is used.`}</p>
    <h5 {...{
      "id": "listbox-name"
    }}>{`Listbox name`}</h5>
    <p><inlineCode parentName="p">{`name?: string`}</inlineCode></p>
    <p>{`The name used for the listbo input's form value.`}</p>
    <h5 {...{
      "id": "listbox-onchange"
    }}>{`Listbox onChange`}</h5>
    <p><inlineCode parentName="p">{`onChange?(newValue: string): void;`}</inlineCode></p>
    <p>{`The callback that fires when the listbox value changes.`}</p>
    <h5 {...{
      "id": "listbox-portal"
    }}>{`Listbox portal`}</h5>
    <p><inlineCode parentName="p">{`portal?: boolean`}</inlineCode></p>
    <p>{`Whether or not the popover should be rendered inside a portal. Defaults to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "listbox-required"
    }}>{`Listbox required`}</h5>
    <p><inlineCode parentName="p">{`required?: boolean;`}</inlineCode></p>
    <p>{`Whether or not the listbox input's form field is required.`}</p>
    <h5 {...{
      "id": "listbox-value"
    }}>{`Listbox value`}</h5>
    <p><inlineCode parentName="p">{`value?: string`}</inlineCode></p>
    <p>{`The current value of a controlled listbox.`}</p>
    <h3 {...{
      "id": "listboxinput"
    }}>{`ListboxInput`}</h3>
    <p>{`The top-level component and context provider for the listbox.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<div>
  <span id="my-label">Choose a topic</span>
  <ListboxInput aria-labelledby="my-label" defaultValue="comedy">
    <ListboxButton />
    <ListboxPopover>
      <ListboxList>
        <ListboxOption value="drama">Drama</ListboxOption>
        <ListboxOption value="comedy">Comedy</ListboxOption>
        <ListboxOption value="suspense">Suspense</ListboxOption>
        <ListboxOption value="horror">Horror</ListboxOption>
      </ListboxList>
    </ListboxPopover>
  </ListboxInput>
</div>
`}</code></pre>
    <h4 {...{
      "id": "listboxinput-css-selectors"
    }}>{`ListboxInput CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-listbox-input] {
}
[data-reach-listbox-input][data-state="idle"] {
}
[data-reach-listbox-input][data-value="VALUE_REF"] {
}
`}</code></pre>
    <h4 {...{
      "id": "listboxinput-props"
    }}>{`ListboxInput Props`}</h4>
    <PropTable componentName="ListboxInput" propDefs={listboxDefs.ListboxInput.propDefs} mdxType="PropTable" />
    <h5 {...{
      "id": "listboxinput-children"
    }}>{`ListboxInput children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode | ((props: { value: string | null; valueLabel: string | null; isExpanded: boolean; }) => React.ReactNode)`}</inlineCode></p>
    <p>{`The composed listbox expects to receive `}<inlineCode parentName="p">{`ListboxButton`}</inlineCode>{` and `}<inlineCode parentName="p">{`ListboxPopover`}</inlineCode>{` as children. You can also pass in arbitrary wrapper elements if desired.`}</p>
    <p>{`If you want access to the listbox's current value and associated label, or its expanded state, you can use a render function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<ListboxInput>
  {({ value, valueLabel, isExpanded }) => (
    <ListboxButton>
      <span data-value={value}>{valueLabel}</span>
    </ListboxButton>
    <ListboxPopover>
      <ListboxList>
        <ListboxOption value="apple">Apple 🍏</ListboxOption>
        <ListboxOption value="orange">Orange 🍊</ListboxOption>
        <ListboxOption value="banana">Banana 🍌</ListboxOption>
      </ListboxList>
    </ListboxPopover>
  )}
</ListboxInput>
`}</code></pre>
    <h5 {...{
      "id": "listboxinput-defaultvalue"
    }}>{`ListboxInput defaultValue`}</h5>
    <p><inlineCode parentName="p">{`defaultValue?: string`}</inlineCode></p>
    <p>{`The default value of an uncontrolled listbox.`}</p>
    <h5 {...{
      "id": "listboxinput-disabled"
    }}>{`ListboxInput disabled`}</h5>
    <p><inlineCode parentName="p">{`disabled?: boolean`}</inlineCode></p>
    <p>{`Whether or not the listbox is disabled.`}</p>
    <h5 {...{
      "id": "listboxinput-form"
    }}>{`ListboxInput form`}</h5>
    <p><inlineCode parentName="p">{`form?: string`}</inlineCode></p>
    <p>{`The ID of a form associated with the listbox and its hidden input field. If Listbox is passed directly inside of its associated form this prop can be omitted so long as the `}<inlineCode parentName="p">{`name`}</inlineCode>{` prop is used.`}</p>
    <h5 {...{
      "id": "listboxinput-name"
    }}>{`ListboxInput name`}</h5>
    <p><inlineCode parentName="p">{`name?: string`}</inlineCode></p>
    <p>{`The name used for the listbo input's form value.`}</p>
    <h5 {...{
      "id": "listboxinput-onchange"
    }}>{`ListboxInput onChange`}</h5>
    <p><inlineCode parentName="p">{`onChange?(newValue: string): void;`}</inlineCode></p>
    <p>{`The callback that fires when the listbox value changes.`}</p>
    <h5 {...{
      "id": "listboxinput-required"
    }}>{`ListboxInput required`}</h5>
    <p><inlineCode parentName="p">{`required?: boolean;`}</inlineCode></p>
    <p>{`Whether or not the listbox input's form field is required.`}</p>
    <h5 {...{
      "id": "listboxinput-value"
    }}>{`ListboxInput value`}</h5>
    <p><inlineCode parentName="p">{`value?: string`}</inlineCode></p>
    <p>{`The current value of a controlled listbox.`}</p>
    <h3 {...{
      "id": "listboxbutton"
    }}>{`ListboxButton`}</h3>
    <p>{`The interactive toggle button that triggers the popover for the listbox.`}</p>
    <h4 {...{
      "id": "listboxbutton-css-selectors"
    }}>{`ListboxButton CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-listbox-button] {
}
[data-reach-listbox-button][aria-expanded="true"] {
}
[data-reach-listbox-button][aria-disabled="true"] {
}
`}</code></pre>
    <h4 {...{
      "id": "listboxbutton-props"
    }}>{`ListboxButton Props`}</h4>
    <PropTable componentName="ListboxButton" propDefs={listboxDefs.ListboxButton.propDefs} mdxType="PropTable" />
    <h5 {...{
      "id": "listboxbutton-arrow"
    }}>{`ListboxButton arrow`}</h5>
    <p><inlineCode parentName="p">{`arrow?: boolean | React.ReactNode`}</inlineCode></p>
    <p>{`Renders a text string or React node to represent an arrow inside the button\`.`}</p>
    <h5 {...{
      "id": "listboxbutton-children"
    }}>{`ListboxButton children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode | ((props: { value: string | null; label: string; isExpanded: boolean; }) => React.ReactNode)`}</inlineCode></p>
    <p>{`A render function or React node to to render the button's inner content.`}</p>
    <p>{`By default, the button will display the text label of the selected option as its inner content. This label can be pulled from the option's inner text content or explicitly provided to the ListboxOption component via the label prop. If you want to render the button differently from its default, you must pass children.`}</p>
    <h5 {...{
      "id": "rendering-listboxbutton-on-the-server"
    }}>{`Rendering ListboxButton on the server`}</h5>
    <p>{`It's important to note that the ListboxButton's default inner content cannot be server-side rendered. On the initial render, the button has no contextual information about the available options in a Listbox. As each ListboxOption is rendered, it is registered in a context object and updated at the top of the Listbox tree, which evaluates the options and their props to determine which option is selectable and which label to display inside the button. If you need the inner content of the button on the first render you must control the listbox's state and keep its options' values and labels in data at the top of the tree, and render the button directly via children.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`let options = { one: "One option", two: "Another option" };
let [value, setValue] = React.useState(options.one);
return (
  <ListboxInput>
    <ListboxButton>{options[value]}</ListboxButton>
    <ListboxPopover>
      <ListboxList>
        {Object.keys(options).map((option) => (
          <ListboxOption key={option} value={option} label={options[option]}>
            {options[option]}
          </ListboxOption>
        ))}
      </ListboxList>
    </ListboxPopover>
  </ListboxInput>
);
`}</code></pre>
    <h3 {...{
      "id": "listboxarrow"
    }}>{`ListboxArrow`}</h3>
    <p>{`A wrapper component for an arrow to display in the `}<inlineCode parentName="p">{`ListboxButton`}</inlineCode>{`.`}</p>
    <p>{`You can use your own wrapper component if you prefer, but if its inner content contains anything that can be read by assistive devices you should use `}<inlineCode parentName="p">{`aria-hidden`}</inlineCode>{` on the wrapper element, as the arrow should have no semantic value.`}</p>
    <h4 {...{
      "id": "listboxarrow-css-selectors"
    }}>{`ListboxArrow CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-listbox-arrow] {
}
[data-reach-listbox-arrow][data-expanded] {
}
`}</code></pre>
    <h4 {...{
      "id": "listboxarrow-props"
    }}>{`ListboxArrow Props`}</h4>
    <PropTable componentName="ListboxArrow" propDefs={listboxDefs.ListboxArrow.propDefs} mdxType="PropTable" />
    <h5 {...{
      "id": "listboxarrow-children"
    }}>{`ListboxArrow children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode | ((props: { isExpanded: boolean }) => React.ReactNode)`}</inlineCode></p>
    <p>{`Children to render as the listbox button's arrow. This can be a render function that accepts the listbox's `}<inlineCode parentName="p">{`isExpanded`}</inlineCode>{` state as an argument.`}</p>
    <h3 {...{
      "id": "listboxpopover"
    }}>{`ListboxPopover`}</h3>
    <p>{`The popover containing the list of options.`}</p>
    <h4 {...{
      "id": "listboxpopover-css-selectors"
    }}>{`ListboxPopover CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-listbox-popover] {
}
[data-reach-listbox-popover][hidden] {
}
`}</code></pre>
    <h4 {...{
      "id": "listboxpopover-props"
    }}>{`ListboxPopover Props`}</h4>
    <PropTable componentName="ListboxPopover" propDefs={listboxDefs.ListboxPopover.propDefs} mdxType="PropTable" />
    <h5 {...{
      "id": "listboxpopover-children"
    }}>{`ListboxPopover children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode`}</inlineCode></p>
    <p><inlineCode parentName="p">{`ListboxPopover`}</inlineCode>{` expects to receive `}<inlineCode parentName="p">{`ListboxList`}</inlineCode>{` as its children.`}</p>
    <h5 {...{
      "id": "listboxpopover-portal"
    }}>{`ListboxPopover portal`}</h5>
    <p><inlineCode parentName="p">{`portal?: boolean`}</inlineCode></p>
    <p>{`Whether or not the popover should be rendered inside a portal. Defaults to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <h5 {...{
      "id": "listboxpopover-position"
    }}>{`ListboxPopover position`}</h5>
    <p><inlineCode parentName="p">{`position?(targetRect?: DOMRect | null; popoverRect?: DOMRect | null): React.CSSProperties`}</inlineCode></p>
    <p>{`The positioning function for the popover.`}</p>
    <h3 {...{
      "id": "listboxlist"
    }}>{`ListboxList`}</h3>
    <p>{`The list containing all listbox options.`}</p>
    <h4 {...{
      "id": "listboxlist-css-selectors"
    }}>{`ListboxList CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-listbox-list] {
}
`}</code></pre>
    <h3 {...{
      "id": "listboxoption"
    }}>{`ListboxOption`}</h3>
    <p>{`A selectable option for the listbox.`}</p>
    <h4 {...{
      "id": "listboxoption-css-selectors"
    }}>{`ListboxOption CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-listbox-option] {
  /* styles for all listbox options */
}
[data-reach-listbox-option][data-current-selected] {
  /* styles for the option matching the current value of the input */
}
[data-reach-listbox-option][data-current-nav] {
  /* styles for the option matching the user's navigation selection */
}
[data-reach-listbox-option][aria-disabled="true"] {
  /* styles for disabled listbox options */
}
`}</code></pre>
    <h4 {...{
      "id": "listboxoption-props"
    }}>{`ListboxOption Props`}</h4>
    <PropTable componentName="ListboxOption" propDefs={listboxDefs.ListboxOption.propDefs} mdxType="PropTable" />
    <h5 {...{
      "id": "listboxoption-disabled"
    }}>{`ListboxOption disabled`}</h5>
    <p><inlineCode parentName="p">{`disabled?: boolean`}</inlineCode></p>
    <p>{`Whether or not the option is disabled from selection and navigation.`}</p>
    <h5 {...{
      "id": "listboxoption-label"
    }}>{`ListboxOption label`}</h5>
    <p><inlineCode parentName="p">{`label?: string`}</inlineCode></p>
    <p>{`The option's human-readable label. This prop is optional but highly encouraged if your option has multiple text nodes that may or may not correlate with the intended value, or if the inner text is really long (all text will be read by a screen reader by default, which can create a confusing experience).`}</p>
    <p>{`It is also useful if the inner text node begins with a character other than a readable letter (like an emoji or symbol) so that typeahead works as expected for the user.`}</p>
    <h5 {...{
      "id": "listboxoption-value"
    }}>{`ListboxOption value`}</h5>
    <p><inlineCode parentName="p">{`value: string`}</inlineCode></p>
    <p>{`The option's value. This will be passed into a hidden input field for use in forms when the option is selected.`}</p>
    <h3 {...{
      "id": "listboxgroup"
    }}>{`ListboxGroup`}</h3>
    <p>{`A group of related listbox options.`}</p>
    <h4 {...{
      "id": "listboxgroup-css-selectors"
    }}>{`ListboxGroup CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-listbox-group] {
}
`}</code></pre>
    <h4 {...{
      "id": "listboxgroup-props"
    }}>{`ListboxGroup Props`}</h4>
    <PropTable componentName="ListboxGroup" propDefs={listboxDefs.ListboxGroup.propDefs} mdxType="PropTable" />
    <h5 {...{
      "id": "listboxgroup-label"
    }}>{`ListboxGroup label`}</h5>
    <p><inlineCode parentName="p">{`label?: string`}</inlineCode></p>
    <p>{`The text label to use for the listbox group. This can be omitted if a group contains a `}<inlineCode parentName="p">{`ListboxGroupLabel`}</inlineCode>{` component. The label should always be human-readable.`}</p>
    <h3 {...{
      "id": "listboxgrouplabel"
    }}>{`ListboxGroupLabel`}</h3>
    <p>{`A label identifier for a `}<inlineCode parentName="p">{`ListboxGroup`}</inlineCode>{`. This can be used in lieu of the `}<inlineCode parentName="p">{`label`}</inlineCode>{` prop in `}<inlineCode parentName="p">{`ListboxGroup`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<ListboxGroup>
  <ListboxGroupLabel>Veggies</ListboxGroupLabel>
  <ListboxOption value="broccoli">Broccoli 🥦</ListboxOption>
  <ListboxOption value="carrot">Carrot 🥕</ListboxOption>
  <ListboxOption value="tomato">Tomato 🍅</ListboxOption>
</ListboxGroup>
`}</code></pre>
    <h4 {...{
      "id": "listboxgrouplabel-css-selectors"
    }}>{`ListboxGroupLabel CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-listbox-group-label] {
}
`}</code></pre>
    <h3 {...{
      "id": "uselistboxcontext"
    }}>{`useListboxContext`}</h3>
    <p><inlineCode parentName="p">{`function useListboxContext(): { id: string | undefined; isExpanded: boolean; value: string | null; valueLabel: string | null }`}</inlineCode></p>
    <p>{`A hook that exposes data for a given `}<inlineCode parentName="p">{`ListboxContext`}</inlineCode>{` component to its descendants.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      